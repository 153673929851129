.dn {
  display: none;
}

.viewbtn {
  background: transparent;
  border: none;
  padding: 0;
}

.mobileintro {
  display: none;
}
.mobileintro p{
  font-size: 14px !important;
}
.questionpopup .slick-prev, .questionpopup .slick-next {
  display: none !important
}

.responsiveselect{
  background-position-x:calc(100% - 16px);
}
@media only screen and (max-width:769px) {
  .secheading {
    font-size: 20px;
  }
.waitingpage > h1{
  font-size:26px;
  font-weight:600;
}
.waitingpage > h5{
  font-size:14px;
  line-height:1.5
}
  .imagessliderprofessional p>span {
    font-size: 12px !important;
  }

  .imagessliderprofessional p>span:nth-child(2) {
    margin-top: 10px !important;
    display: inline-block;
  }

  .imagessliderprofessional .reviewer-name {
    font-size: 17px;
  }
.responsiveselect{
  padding-right:15px;
}
  .dasktopintro {
    display: none;
  }

  .mobileintro {
    display: block !important;
  }

  .phonehide {
    display: none;
  }

  .footer-sm-link.mt-5 {
    margin: 0 !important;
  }

  #zoomid .distroy {
    margin: 0 !important;
    padding: 0 !important;
  }

  .clearb {
    float: right;
    margin-right: 25px;
  }

  .dn {
    display: inline-block;
    cursor: pointer;
  }

  .profset>.col-sm-12 {
    padding: 0 !important;
  }

  .profset>.col-sm-12 .col-12 {
    margin: 0 auto;
  }

  .profset>.col-sm-12 .col-12 .form-group {
    padding: 0 !important;
  }

  .profset>.col-sm-12 .paddCss {
    padding: 7px 0 !important;
  }

  .profset .col-11 {
    padding: 0 !important;
  }

  .ordersmr .questionadmin {
    padding-top: 0 !important;
  }

  .questionadmin p {
    margin-bottom: 8px;
  }

  .Banner img.pricingBanner {
    height: 150px;
  }

  .resp .paddCss {
    padding-bottom: 0;
  }

  .questionadmin p {
    font-size: 16px;
  }

  .PrivateHiddenCss-xsDown-16, .makeStyles-drawer-5 {
    display: none;
  }

  .privacyBody {
    margin-top: 30px;
  }

  .bannerText {
    white-space: nowrap;
  }

  .signUp-form-wrapper {
    padding: 20px !important;
  }

  .lognaddd {
    display: block !important;
    white-space: nowrap;
    text-align: center;
    margin: 0 !important;
  }

  .lognaddd span {
    display: block;
    margin-bottom: 10px;
  }

  .privacyBody h1 {
    text-align: center !important;
    font-size: 1.5rem;
  }

  .privacyBody h2 {
    font-size: 1.3rem;
    margin-bottom: 0 !important;
  }

  .hidepart {
    transform: scaleY(0);
    height: 0;
    transition: 0.5s;
  }

  .hidepart.row {
    margin-left: 0 !important;
  }

  .mv {
    display: block;
    cursor: pointer;
  }

  .head11 h4 {
    font-size: 17px !important;
  }

  .servicesfull .row.mb-4 {
    margin-bottom: 0 !important;
  }

  #second-search {
    font-size: 12px;
  }

  #search-button {
    margin-left: 7px !important;
  }

  #searchHeading, .howItWorkHeading {
    text-align: center;
    font-size: 24px !important;
    margin-bottom: 0 !important;
    margin-top: 40px !important;
  }

  .uploadtype {
    display: block;
    margin-top: 10px;
  }

  .chooseBannerPosition h3 {
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .btn-group-lg>.btn, .btn-lg {
    font-size: 1rem;
  }

  .askedQuestion {
    font-size: 17px;
  }

  #searchHeadingSecond {
    text-align: center;
    font-size: 18px;
  }

  .headi, .WeeklyDetails .col-sm-8 {
    margin: 0 !important;
  }

  .WeeklyDetails .col-sm-8, .WeeklyDetails .pl-0, .erng {
    padding: 0 !important;
  }

  .erng .paddCss {
    padding: 12px;
  }



  .erng .paddCss button {
    height: 35px !important;
    width: 78px !important;
  }

  .container .datesearch #search-button {
    height: 35px !important;
  }

  img.Banner {
    height: 150px;
  }

  .hidetable {
    display: none;
  }

  .dtview {
    display: block;
  }

  .paddCss2 label strong, .paddCss2 span.dark {
    font-size: 12px !important;
  }

  .table.text-center td {
    font-size: 14px !important;
  }

  .MuiIconButton-root:hover {
    background-color: transparent !important;
  }

  .paddCss2 #search-button {
    height: 27px !important;
    width: 80px !important;
  }

  .borderP button {
    margin: 0 !important;
  }

  .backnone>div {
    max-width: 94% !important;
    padding: 0 3% !important;
  }

  .DatePicker {
    width: 68% !important;
  }

  .paddLeft {
    padding-right: 20px !important;
  }

  .datesearch.poi input {
    min-width: 75px !important;
    width: 30%;
  }

  .erng .header, .erng .header p {
    text-align: center;
    margin: 0 !important;
  }

  .datesearch span {
    margin: 0 10px;
  }

  .datesearch input {
    min-width: 200px !important;
    width: 100%;
  }

  .ordrblock {
    display: block !important;
  }

  .Homepage-header {
    z-index: 9999;
    margin-bottom: -155px;
  }

  .imageBanner {
    margin-left: 26px;
  }

  .topBarMenu.responsive {
    float: none;
    display: block;
    text-align: left;
  }

  .cleaningbanners {
    display: none;
  }

  .professionalSearch ul {
    width: 100% !important;
    margin: 0 !important;
    text-align: left !important;
  }

  .professionalReview .ratng, .user_image .rating {
    width: 107px;
    float: left;
    margin-left: 0;
  }

  .user_image p {
    text-align: center;
  }

  .zoom {
    margin-bottom: 15px;
  }

  .MuiPopover-root .MuiPopover-paper {
    left: 50% !important;
    transform: translateX(-50%) !important;
  }

  .all-Orders {
    padding: 0 15px;
  }

  .mb20 {
    padding-bottom: 30px;
  }

  .btnset {
    text-align: center !important;
  }

  .ordersmr {
    margin: 15px 0 !important;
    padding: 30px 15px !important;
  }

  .ordersmr .col-6 {
    padding: 0 !important;
    font-size: 14px !important;
    overflow: hidden;
  }

  .css-1wa3eu0-placeholder {
    font-size: 13px;
    white-space: nowrap;
  }

  .css-1uccc91-singleValue {
    font-size: 14px;
  }
}

.ordrblock tbody:nth-child(2n + 1) {
  background-color: #eee;
}

.overflowqsn {
  overflow-y: scroll !important;
  max-height: 500px;
  scrollbar-width: thin;
}

.cancelb {
  position: absolute;
  border-radius: 50%;
  border: 1px solid #ccc !important;
  width: 34px;
  height: 34px;
  text-align: center;
}

.backblack {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1125;
}

@media only screen and (max-width:1440px) {
  .custom-file-input::before {
    width: 39%;
    margin-left: 64%;
  }
}

.searchBox::placeholder, ._3vt7_Mh4hRCFbp__dFqBCI input::placeholder {
  color: gray;
}

@media only screen and (max-width:1024px) {
  custom-file-input::before {
    content: 'Choose File';
    display: block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    /* border: 1px solid #999; */
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    /* font-weight: 700; */
    font-size: 20pt;
    width: 38%;
    text-align: center;
    background: #a2a2a7;
    margin-left: 65%;
    height: inherit;
    color: #FFF;
    margin-top: -7px;
    padding: 6px;
  }

  .providerBox {
    padding: 60px;
    margin-top: 0;
  }

  .filterBox {
    padding: 0 37px;
  }
}

@media only screen and (max-width:1190px) {
  #search-button {
    margin-left: 0;
    margin-right: 0 !important;
  }

  .BeforeLogin {
    top: -10%;
  }

  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
  }

  .first-searchs {
    width: 180px;
  }







  #login-btn {
    width: 87px;
  }

  .estimate {
    width: 100%;
  }

  #joinas {
    width: 100% !important;
    white-space: nowrap;
    padding: 6px 25px;
  }
}

@media only screen and (max-width:560px) {
  .welcome {
    font-size: 22px;
  }

  .overflowqsn {
    overflow: scroll !important;
  }

  .paddCss1 label {
    font-size: 13px !important;
  }

  .datepopup, .notepad, .questionpopup {
    max-width: 100%;
    width: 96%;
    margin: 0 2% 0 0;
    padding: 25px 15px !important;
  }

  .buttons {
    max-width: 230px;
  }

  .questionpopup .option {
    width: 100% !important;
  }

  .questionpopup form {
    margin: 0 !important;
    width: 100% !important;
  }

  .paddLeft.categ>.form-control>.row div:first-child {
    white-space: nowrap;
    overflow: hidden;
  }

  .modal-open .modal {
    padding-left: 0 !important;
  }

  #map-icon {
    margin-bottom: 10px !important;
  }

  #map-icon~li button {
    margin: 0 !important;
  }

  .nd {
    display: none;
  }

  .avlbt {
    margin-top: 30px;
  }


  .avlbt .col-sm-6 button {
    margin: 0 !important;
  }

  .dropdown2 {
    right: -66px;
  }

  .MuiPickersBasePicker-pickerView {
    min-width: 287px !important;
  }

  .select-action {
    width: 80px !important;
    text-align: left !important;
    padding-left: 3px !important;
  }

  .setbtn {
    padding: 0 !important;
  }

  .button.pastclass {
    padding: 0 !important;
    font-size: 16px !important;
  }

  .MuiButton-text {
    padding: 0 !important;
    min-width: 42px !important;
  }

  .makeStyles-content-9 .container>.row {
    padding: 0 20px !important;
    margin: 0 -12px !important;
  }

  .margin0 {
    margin: 15px 0 !important;
  }

  .paddLeft {
    padding: 0 5px !important;
  }

  .position .button {
    padding: 6px 35px;
    margin-right: 10px !important;
  }



  .header2 {
    padding: 20px 5px !important;
  }

  .float-right .button {
    margin: 0 !important;
  }

  .container>.row-width2>.col-sm-12 {
    padding: 0 !important;
  }

  .list-inline-item:not(:last-child) {
    margin-right: 10px !important;
    padding: 0 !important;
  }

  #map-icon.list-inline-item:not(:last-child) {
    margin-right: 5px !important;
  }

  .borderP .header2>.col-sm-12 .ml {
    margin-left: 30px !important;
  }

  .borderP .header2>.col-sm-12 .m2l {
    margin-left: 20px !important;
  }

  .search-box #search-button {
    width: 85px !important;
  }

  .m0 {
    margin: 0 !important;
  }

  #cancelPost {
    width: 100% !important;
  }

  .reviewer-name {
    font-size: 20px;
  }

  .dailyupdate h4 {
    font-size: 15px !important;
  }

  .makeStyles-content-9 .container, .makeStyles-content-9 .container>.row-width {
    padding: 0 !important;
  }

  .earnig_image {
    padding: 26px 4px !important;
  }

}