@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

body {
    /* font-family: poppins; */
    font-family: 'Nunito', sans-serif;
}

a {
    transition: 0.5s !important;
}

.list-inline-item button, .list-inline-item input, #login-btn, #sign-btn, #joinas, input {
    font-family: 'Nunito', sans-serif;
}

.sec-1 {
    overflow: hidden;
    margin-top: -110px;
}

.slick-next:before {
    color: #2b5ea6 !important;
}

.estimate {
    position: relative;
}

.estimate .MuiFormControl-marginNormal {
    border: none !important;
    padding: 0 !important;
    margin: 0 !important;
    position: absolute;
    left: 0;
    bottom: 62px;
    width: 100%;
    display: block;
    z-index: 4;
    opacity: 0;
}

.estimate .MuiPopover-paper {
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.estimate .MuiInputBase-input {
    display: none;
}

.revrat {
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .125);
}

.revrat h5 {
    font-size: 17px;
    color: #333;
    margin: 0;
    font-weight: 700;
}

.revrat textarea {
    background-color: #eeeeee5c;
    border: none;
    width: 100%;
    padding: 15px;
    border-bottom: 1px solid #ccc;
    margin-top: 10px;
}

#timefield {
    min-width: 110px !important
}

.estimate .MuiIconButton-root, .estimate .MuiInputAdornment-positionEnd, .estimate .MuiInput-root {
    width: 100%;
    display: block;
}

.MuiPickersDay-dayDisabled {
    /* color: #fff !important; */
    padding: 3px !important;
}

.MuiPickersDay-dayDisabled .MuiIconButton-label {
    height: 100%;
    border-radius: 50%;
    /* background-color: red !important; */
}

.slick-prev:before {
    color: 2b5ea6 !important;
}

.clearb {
    padding: .100rem .75rem;
}

.Homepage .Homepage-header {
    /* margin-bottom: -100px; */
}

.filterByBox .MuiSlider-thumb {
    width: 20px;
    height: 20px;
    margin-top: -9px;
}

.filterByBox .MuiSlider-valueLabel {
    left: calc(-50% - -4px);
}

.filterByBox .MuiSlider-root {
    width: 90%;
}

.filterByBox .MuiSlider-track, .filterByBox .MuiSlider-rail {
    height: 4px;
}

.Homepage-header {
    z-index: 9999;
    margin: 20px 0;
}

.heading-1 {
    color: #353b48;
    font-weight: bold !important;
}

.heading-2 {
    color: #2b5ea6;
    font-weight: bold !important;
}

.bottomLine {
    text-align: center;
}

.modal-header {
    padding: 0.5rem 1rem;
}

.sectionfirst {
    width: 100%;
    height: auto;
}

.secheading {
    text-align: center;
    font-size: 2rem;
}

label:not(.form-check-label):not(.custom-file-label) {
    font-weight: 500;
}

label {
    margin-bottom: .1rem;
}

.sec-2 {
    overflow: hidden;
    background: #f5f6fa;
}

.sec-3 {
    overflow: hidden;
}

.sec-4 {
    overflow: hidden;
    background: #f5f6fa;
}

.find-the-perfect {
    color: #333333;
    margin-top: 208px;
    position: absolute;
    margin-left: 106px;
}

.errorMessage {
    color: red;
    font-size: 0.75em;
    display: relative;
}

button:focus {
    outline: none;
}

.line {
    border: 0px;
    border-top: 6px solid blue;
}

.poplink {
    background: white;
    /*text-align: center;*/
    position: relative;
    margin-top: -17px;
    width: 98px;
    /*	padding:5px;
*/
}

.poplink a {
    font-size: 20px;
    color: #353b48;
}

.poplink a:hover {
    text-decoration: none;
}

.slick-image:focus {
    outline: none;
}

.owl-theme .owl-nav [class*=owl-]:hover {
    /*background: #869791;*/
    /*color: #FFF;*/
    text-decoration: none;
}

.slick-image {
    border-bottom: 5px solid #0118b5;
}

input.error {
    border: 1px solid red;
}

#sign-btn {
    border-radius: 20px;
    background-color: #353b48;
    border-color: #353b48;
    width: 100px;
}

#joinas {
    border-radius: 20px;
    background-color: #2b5ea6;
    color: #FFF;
    border-color: #2b5ea6;
    width: 205px;
}

#header-menu {
    float: right;
    list-style: none;
    display: inline-flex;
}

#header-menu li {
    padding: 11px 0px 0px 12px;
}

.slick-image:after {
    display: block;
    left: 0;
    /*bottom: -20px;*/
    width: 0;
    height: 8px;
    background-color: #cc121a;
    content: "";
    transition: width 0.1s;
    margin-top: 12px;
}

.title-of-image {
    margin: 0 !important;
}

.approvedtext {
    color: green !important;
    font-size: 20px !important;
    display: block;
    white-space: nowrap;
}

.notapprovedtext {
    color: red !important;
    font-size: 20px !important;
    display: block;
    white-space: nowrap;
}

.servicesfull img {
    width: 100%;
    max-width: 100%;
}

.slick-image {
    border-bottom: 8px solid blue;
}

div:focus {
    outline: none;
}

.slick-image:hover:after {
    width: 100%;
}

/* .Toastify .Toastify__toast--error {
  display: none !important;
} */

.processbar {
    display: block;
    width: 100%;
    padding-bottom: 20px;
}

.processbar ul {
    padding: 0;
}

.processbar ul li {
    display: inline-block;
    width: 33.33%;
    position: relative;
    text-align: center;
    font-size: 18px;
    padding-bottom: 10px;
    color: #ccc;
}

.processbar ul li.complete {
    color: green;
}

.processbar ul li::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    display: block;
    background-color: #ccc;
    height: 2px;
}

.ordermulty {
    border: none;
    padding: 0px 22px;
    margin: 0;
    text-align: center;
    background-position-y: 0px;
    cursor: pointer;
}

.processbar ul li.complete::before {
    background-color: green;
}

.slick-image:hover {
    border-bottom: none;
}

.user_card {
    height: 400px;
    width: 350px;
    margin-top: auto;
    margin-bottom: auto;
    background: #FFF;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
}

.send {
    padding: 4px 43px !important;
}

.brand_logo_container {
    position: absolute;
    height: 170px;
    width: 170px;
    top: -75px;
    border-radius: 50%;
    background: #60a3bc;
    padding: 10px;
    text-align: center;
}

.brand_logo {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    border: 2px solid white;
}

.form_container {
    /*margin-top: 100px;*/
}

.btn-sendotp {
    padding: inherit;
    margin-top: 2px;
    margin-bottom: 22px;
    border-radius: 20px;
}

.btn-signInSubmit {
    width: 50%;
    border-color: 2b5ea6 !important;
    background: 2b5ea6 !important;
    color: white !important;
    border-radius: 20px;
}

.btn-signInSubmit:focus {
    box-shadow: none !important;
    outline: 0px !important;
}

.input-group-text {
    background: #3dbdf3 !important;
    color: white !important;
    border: 0 !important;
    border-radius: 0.25rem 0 0 0.25rem !important;
}

.input_user, .input_pass:focus {
    box-shadow: none !important;
    outline: 0px !important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: #c0392b !important;
}

.page-footer {
    background: #353b48;
}

.footer-heading {
    color: #FFF;
}

.list-unstyled li {
    padding-bottom: 8px;
}

.list-unstyled li a {
    color: #FFF;
}

.ftr {
    text-align: left;
    width: 100%;
    color: #fff;
    font-weight: 600;
    padding: 0 0px 10px;
    font-size: 1.2rem;
}

.links #sign-btn {
    border: none;
    ;
    background: #FFF;
    color: blue;
    padding: 0px 36px 10px 0px;
}

.links #sign-btn:focus {
    box-shadow: none;
}

.footer-line {
    color: #fff;
    margin-left: 105px;
    margin-right: 100px;
    border-top: 1px solid rgb(255 255 255);
}

/* Style the form */

#regForm {
    background-color: #ffffff;
    margin: 100px auto;
    padding: 40px;
    width: 70%;
    min-width: 300px;
}

/* Style the input fields */

input {
    padding: 4px 10px !important;
    width: 100%;
    font-size: 17px;
    font-family: Raleway;
    border: 1px solid #aaaaaa;
    -moz-appearance: textfield;
}

.form-group {
    margin-bottom: 0.5rem;
}

/* Mark input boxes that gets an error on validation: */

input.invalid {
    background-color: #ffdddd;
}

/* Hide all steps by default: */

.tab {
    display: none;
}

/* Make circles that indicate the steps of the form: */

.step {
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbbbbb;
    border: none;
    border-radius: 50%;
    display: inline-block;
    opacity: 0.5;
}

/* Mark the active step: */

.step.active {
    opacity: 1;
}

/* Mark the steps that are finished and valid: */

.step.finish {
    background-color: #4CAF50;
}

.next, .previous, .submit {
    float: right;
}

.custom-file-input {
    position: absolute;
    z-index: -1;
    width: 70%;
    height: calc(2.5em + .75rem + 2px);
    margin: 0;
    opacity: 1;
}

.new-btn {
    margin-left: 77%;
    display: inline-block;
    padding: 15px 12px;
    cursor: pointer;
    border-radius: 4px;
    background: #a2a2a7;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    font-size: 16px;
    color: #fff;
    outline: none;
    white-space: nowrap;
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

div.fileinputs {
    position: relative;
}

div.fakefile {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
}

.newCssOne {
    position: relative;
    text-align: right;
    -moz-opacity: 0;
    filter: 0;
    opacity: 0;
    z-index: 2;
}

form {
    width: 100%;
    flex-wrap: wrap;
}

input.error {
    border: 1px solid red;
}

.errorMessage {
    color: red;
    font-size: 0.75em;
    display: relative;
}

.comingsoon {
    position: relative;
}

.cyellow {
    color: #fffb00b3 !important;
}

.cursoin_none span {
    cursor: none !important;
}

.comingsoon span {
    position: absolute;
    left: 50px;
    top: 0px;
    background: #fff;
    padding: 3px 7px;
    border: rebeccapurple;
    border-radius: 5px;
    color: #666;
    font-weight: 600;
    font-size: 11px;
    transform: scale(0);
    transition: 0.3s;
}

.comingsoon:hover span {
    transform: scale(1);
    transition: 0.3s;
}

.hide {
    display: none;
}

.show {
    display: block;
}

svg {
    overflow: hidden;
    vertical-align: middle;
    font-size: 25px;
}

.next, .submit, .previous {
    background: #353b48;
}

info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #353b48;
    border-color: #353b48;
}

.btn-info:hover {
    color: #fff;
    background-color: #353b48;
    border-color: #353b48;
}

.btn-info {
    color: #fff;
    background-color: #353b48;
    border-color: #353b48;
}

.document {
    display: block;
    width: 100%;
    height: calc(2.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.5;
    color: #FFF;
    background-color: #353b48;
    background-clip: padding-box;
    border: 1px solid #353b48;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    text-align: center;
    font-weight: 600;
}

.user_image>img {
    width: 120px !important;
    height: 120px !important;
}

.upload-document {
    height: calc(3.5em + .75rem + 2px);
}

#regiration_form {
    position: relative;
}

#fieldset3 .row .form-group .form-control {
    overflow: hidden;
    white-space: nowrap;
}

#react-select-3-input {
    padding-left: 0 !important;
}

#fieldset3 .row .form-group .form-control~input {
    opacity: 0;
    width: 90%;
    position: absolute;
    left: 14px;
    top: 0;
    bottom: 0;
    right: 42px;
}

/*.Create-account{
  margin-top: 100px;
}*/

#addressArea {
    width: 100%;
    height: 47px;
    outline: none;
    border: 1px solid #212529;
    border-radius: 8px;
}

/*#subcategory{
    width: 100%;
    height: 40px;
    background: white;
    border-radius: 5px;
    outline: none;
    border: 1px solid #cfcfcf;
     color: #cfcfcf;
}*/
#category option:nth-child(1) {
    color: #989898;
}

.uploadtype {
    font-size: 16px;
}

#category, #subcategory {
    width: 100%;
    height: 40px;
    background: white;
    border-radius: 5px;
    outline: none;
    /* border: 1px solid #252424; */
    /* color: #989898; */
    padding: .375rem .75rem;
    /* line-height: 1.5;
}
#login-form{
  width: 445px;
}
.slick-image{
  text-align:-webkit-center;
  background-color: #FFF;
  margin:5px;
  height: 200px;
}
.slick-slide img{
  padding: 13px;
}
.popular_services{

  background: white;
  text-align: center;
  position: relative;
  margin-top: -23px;
  margin-right:0px;
  margin-left:0px;
  height:40px;
  padding:6px;
}
.slick-dots li button:before {
  font-size: 12px !important;
  /*color:#cc121a !important ; */
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #cc121a !important;
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #cc121a !important;
}

.popular_services a {
    color: #353b48;
    font-size: 19px;
    display: block;
    text-align: center;
    padding: 8px 0;
    font-weight: 700;
}

.popular_services a:hover {
    color: #cc121a;
    text-decoration: none;
}

.Slider-3 .slick-slide img {
    /*padding: 5px;*/
}

.item .slider-3 {
    padding: 12px !important;
}

.popular_services a:hover {
    color: #000;
    text-decoration: none;
}

.footer-sm-link {
    /*transform: translateX(-141px);*/
    /* margin-left: -158px;
  margin-top: -68px;
  padding-top: 56px; */
}

.homepage-heading {
    /*margin-left: -137px;*/
    color: #333333;
    font-weight: 400;
    font-size: 50px;
    /* font-family: poppins; */
    font-family: 'Nunito', sans-serif;
}

.homepage-heading1 {
    /*margin-left: -93px;*/
    color: #333333;
    font-weight: 400;
    font-size: 50px;
    /* font-family: poppins; */
    font-family: 'Nunito', sans-serif;
}

.sub-heading {
    font-size: 30px;
    font-weight: 400;
}

.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 29px !important;
    line-height: 1;
    opacity: .75;
    color: #a9a3af !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: -34px !important;
}

.slick-next {
    right: -34px;
}

button:focus {
    outline: none ! important;
    box-shadow: none ! important;
}

.form-control:focus {
    color: #dcdcdc;
    /*background-color: ##dcdcdc;*/
    border-color: #dcdcdc;
    outline: none ! important;
    box-shadow: none ! important;
}

.custom-file-input.form-control {
    line-height: 2.4 !important;
}

.makeStyles-profilehover-13:first-child {
    border-bottom: 1px solid #000;
}

#first-search {
    border-right: none;
    border-radius: 1px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    width: 300px;
    font-size: 15px;
    height: 45px;
}

#second-search {
    display: inline-block;
    width: 130px;
    border-radius: 0px 3px 3px 0px;
    height: 45px;
    font-size: 17px;
    border: 1px solid #8e8d8dcc;
    border-left: 0;
}

.dtview {
    display: none;
}

.first-searchs {
    border: 1px solid #8e8d8dcc;
    display: inline-block;
    float: left;
    width: 300px;
    font-size: 16px;
    border-radius: 5px 0px 0px 5px;
    height: 45px;
}

.css-yk16xz-control {
    max-height: 38px;
}

.videoplayer {
    margin-left: 21%;
}

#search-button {
    margin-left: -17px;
    background-color: #2b5ea6;
    height: 45px;
    width: 100px;
    margin-bottom: 2px;
}

.professionalsearch {
    height: 45px;
}

.putLeft {
    margin-left: 18%;
}

.radio-inline input {
    width: 30px;
}

.askedQuestion {
    font-size: 20px;
    opacity: 1;
}

._3vt7_Mh4hRCFbp__dFqBCI input::placeholder {
    color: #000 !important;
}

hr {
    margin-top: 0px;
}

input {
    border: 2px solid #cfcfcf;
}

.css-tlfecz-indicatorContainer {
    display: none !important;
}

.css-g1d714-ValueContainer {
    height: 42px;
}

.custom-file-input {
    position: absolute;
    z-index: -1;
    width: 73%;
    height: calc(2.5em + .75rem + 2px);
    margin: 0;
    opacity: 1;
    color: black;
    background: ghostwhite;
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

form {
    width: 100%;
    flex-wrap: wrap;
}

input.error {
    border: 1px solid red;
}

.errorMessage {
    color: red;
    font-size: 0.75em;
    display: relative;
}

.slick-image:hover {
    /*border: solid 1px #CCC;*/
    -moz-box-shadow: 1px 1px 5px #999;
    -webkit-box-shadow: 1px 1px 5px #999;
    box-shadow: 1px 1px 5px #999;
    transform: scale(1.05);
    border-bottom: 8px solid #cc121a;
}

.privacyBody {
    margin-top: 115px;
}

.privacyBody h1 {
    font-weight: 600;
    text-align: left !important;
}

.heading {
    color: #333333;
}

.navbar-homepage {
    display: none;
}

#social-icons {
    float: left;
}

.search-field {
    display: none;
    width: 50%;
}

.slick-slide img {
    width: 100%;
}

.slide-items {
    padding: 15px;
}

.slide-items .item {
    transition: 0.5s;
}

.slide-items .item, .servicesfull a {
    overflow: hidden;
    transition: 0.8s;
}

.slide-items .item img, .servicesfull a img {
    transition: 0.8s;
}

.slide-items .item:hover img, .servicesfull a:hover img {
    transform: scale(1.1);
}

.slide-items .item:hover, .servicesfull .col-md-3>a:hover {
    box-shadow: 1px 1px 7px 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: block;
    background: #fff;
}

.servicesfull .col-md-3>a:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

.servicesfull .col-md-3 {
    margin-bottom: 20px;
}

.row>.mb-2>h3 {
    font-weight: 700;
    text-align: center;
}

.row>.mt-2>h4 {
    font-weight: 700;
    margin-bottom: 30px;
}

.table-heading-first {
    border-radius: 0 !important;
}

.borderP .button {
    margin-top: 20px !important;
}

@media(min-width: 300px) and (max-width: 769px) {
    .heading-1, .heading-2 {
        font-size: 1.1rem !important;
    }

    #search-button {
        height: 44px !important;
    }

    .list-unstyled li {
        padding: 0 !important;
    }

    .booktime p {
        font-size: 15px;
    }

    #time_on .datepopup .makeStyles-container-1 {
        font-size: 13px;
    }

    #time_on .datepopup .makeStyles-container-1 span {
        width: 50% !important;
        float: left;
    }

    #time_on .datepopup .makeStyles-textField-2 {
        width: 50% !important;
        margin: 0;
    }

    #loginUpModel .modal-content {
        border: none !important;
        width: 100% !important;
        margin-top: 80px;
        justify-content: center;
        margin-left: 0px !important;
    }

    .signUp-form-wrapper {
        width: 75% !important;
        height: 820px !important;
        margin-left: -75px !important;
        margin-right: 57px !important;
    }

    #signUpForm .modal-content {
        width: 100% !important;
        margin-top: 77px !important;
        margin-left: 0px !important;
        border: none;
    }

    #loginTabs {
        width: 80% !important;
    }

    #signUpTabs {
        width: 85% !important;
    }

    li.nav-item {
        padding: 12px 12px 0px;
    }

    #signUpModel .modal-content {
        width: 100% !important;
        height: 246px;
        margin-top: 56px;
        margin-left: 0px !important;
        background-color: #FFF;
        /* background: transparent; */
        border: none;
    }

    .MuiGrid-container {
        flex-wrap: inherit !important;
    }

    .MuiGrid-grid-xs-10 {
        max-width: 50% !important;
        width: 50% !important;
    }

    #header-menu>li {
        width: 60px;
        padding-top: 16px !important;
    }

    .MuiGrid-grid-xs-2 {
        max-width: 50% !important;
        width: 100% !important;
    }

    #login-form .modal-content {
        width: 100% !important;
        margin-top: 48px !important;
        margin-left: 0px !important;
    }

    .profile {
        margin-right: 0 !important;
    }

    .borderP .col-sm-12 .row {
        margin: 0 !important;
    }

    .borderP button {
        margin-left: 30px !important;
    }

    .borderP .header2>.col-sm-12>button {
        margin-left: 12px !important;
    }

    .form-wrapper {
        width: 100% !important;
        padding: 11px 20px !important;
    }

    .desktopheader {
        display: none;
    }

    .navbar-homepage {
        margin-top: 156px;
        display: block;
        padding: 14px;
    }

    .navbar-light .navbar-toggler {
        margin-top: 11px;
    }

    .navbar-toggler {
        float: right;
    }

    /* .search-box {
    display: none !important;
  } */
    .BeforeLogin {
        left: 14px !important;
        top: 0% !important;
    }

    .homepage-heading, .homepage-heading1, .sub-heading {
        font-size: 20px;
        margin-top: 10px;
    }

    .footer-copyright.container, #footer-privacy {
        text-align: center !important;
        width: 100%;
        padding-top: 10px;
    }

    .sec-3 .img-fluid {
        width: 100%;
    }

    .footer-heading .text-center {
        text-align: left !important;
    }

    .footer-line {
        margin: 0;
    }

    .find-the-perfect {
        margin-top: 10%;
        margin-left: 17px;
    }

    .slick-arrow {
        display: block !important;
    }

    .slick-prev {
        left: -10px !important;
    }

    .slick-next {
        right: 0 !important;
    }

    .item .slider-3 {
        width: 100%;
    }

    .popular_services {
        margin-right: 0px;
    }

    .slick-image:after {
        display: none;
    }

    #login-btn {
        background-color: transparent;
    }

    .search-field {
        display: none;
    }

    .Banner .container {
        max-width: 100% !important;
        margin: 0 !important;
    }

    .Banner .container img {
        width: 100%;
    }

    #searchHeadingSecond, #searchHeading, .chooseBanner, #connectHeading, #connectHeadingSecond, .payBanner {
        margin-left: 0 !important;
    }

    .container {
        max-width: 100%;
    }

    .Create-account h1 {
        margin-top: 20px;
        font-size: 30px;
    }

    .navbar-homepage li button {
        width: 100% !important;
    }

    .processbar ul li {
        display: none;
    }

    .processbar ul li.complete {
        display: block;
    }

    .processbar ul li {
        width: 100%;
        margin-top: 15px;
    }

    #category, #subcategory {
        margin-right: 0;
    }

    .previous {
        margin: 0 !important;
    }

    .next.btn, .submit {
        margin-bottom: 15px !important;
    }

    .custom-file-input {
        width: 60% !important;
    }

    .new-btn {
        margin-left: 69% !important;
    }

    .resp {
        padding: 0;
        margin: 0;
    }

    .resp .row {
        margin: 0;
        padding: 0;
    }
}

.mv {
    display: none;
}

.mystyle {
    transform: scaleY(1) !important;
    height: 100% !important;
    transition: 0.5s;
}

@media(max-width:425px) {
    #social-icons {
        float: none !important;
    }

    .table.text-center td, .table.text-center th {
        font-size: 13px !important;
    }

    .poplink {
        margin-top: 12px;
    }

    #footer-privacy {
        float: none !important;
    }

    .homepageHeadingFirst {
        font-size: 32px !important;
        margin-bottom: 0.5rem !important;
    }

    .homepageHeadingSecond {
        font-size: 17px;
        margin-bottom: 1rem !important;
    }

    #second-search {
        width: 70px;
    }

    .secheading {
        margin-left: 0;
    }
}

@media(max-width:575px) {
    .dotSetting, .firestDots2, .firestDots3, .firestDots4, .dots {
        display: none !important;
    }

    .professionalReview .ratng, .user_image .rating {
        width: 124px !important;
    }

    .professionalImage>img {
        width: 70px !important;
        height: 70px !important;
    }

    .professionalName {
        margin-left: 15px !important;
    }

    .intro {
        max-height: 40px !important;
        -webkit-line-clamp: 2 !important;
    }

    #map-icon {
        margin: 0;
    }

    .filterBox {
        padding: 0 4px !important;
    }

    .providerBox {
        padding: 10px 30px !important;
        margin-top: 0;
    }

    .professionals {
        font-size: 20px !important;
    }

    .professionalImage {
        display: block !important;
        text-align: left !important;
    }

    .professionalImage>img {
        float: left;
        margin-right: 20px;
    }


    .mov {
        display: block !important;
        float: left;
        font-size: 1rem;
        color: #666;
        margin: 5px 0;
        width: 60%;
        color: #0056b3 !important;
    }

    .professionalName {
        margin: 0 !important;
        margin-top: 10px !important;
    }

    .res {
        float: none !important;
        margin: 0 !important;
    }

    .res .undefined {
        margin: 0 auto;
    }

    .professionalSearch .professionalEstimate {
        padding: 0px !important;
        float: left;
        width: 66%;
        margin: 0 17%;
    }

    .professionalSearch .professionalEstimate h5 {
        margin-top: 5px;
        margin-left: 20px;
    }

    .user_image {
        display: block !important;
        text-align: center;
        float: left;
        width: 100%;
        margin-bottom: 3px;
    }

    .reviewer-name {
        width: 100%;
        display: block;
        margin-left: 0 !important;
        margin-top: 10px;
    }

    .reviewer-name p span {
        margin: 0 !important;
    }

    .user_image>img {
        float: left;
        width: 80px !important;
        height: 80px !important;
    }

    .user_image p {
        text-align: left !important;
    }

    .imagessliderprofessional {
        text-align: center;
        float: left;
        width: 100%;
    }

    .reviewer-name {
        float: left;
        width: calc(100% - 75px);
        text-align: left;
        padding-left: 20px;
        padding-top: 0 !important;
        margin-top: 0;
        font-size: 16px !important;
    }

    .user_image>img {
        float: left;
        width: 70px !important;
        height: 70px !important;
    }

    .reviewer-name p:first-child span {
        font-size: 12px;
    }

    .reviewer-name>p:nth-child(2)>span:nth-child(2) {
        font-size: 12px;
        margin-top: 10px !important;
        display: inline-block;
    }
}

.page-footer .container li a {
    font-size: 14px !important;
}

.page-footer .container .footer-copyright {
    font-size: 14px !important;
}

.ratng {
    display: flex;
}

.ave, .nonerat {
    float: left !important;
    white-space: nowrap;
}

#messagepassford {
    display: none;
}

.passwordsug input:focus~#messagepassford {
    display: block;
}

.mov {
    display: none;
}

.professionalName p p {
    font-size: 15px;
    margin: 0;
    text-align: left;
}

.privacyBody ul li {
    list-style: none;
}

.privacyBody ul li p {
    margin-left: 30px;
}

.privacyBody ul li h3 {
    font-size: 22px;
}

.privacyBody h2 {
    text-align: left;
}

.reviewer-name {
    padding-top: 16px;
}

.slick-image:after {
    display: none;
}

.cleaningbanners {
    position: relative;
    top: 0px;
    right: 0;
    left: 0;
    bottom: 0;
}

.Banner {
    width: 100%;
    overflow: hidden;
}

.margin {
    padding-right: 3%;
}

img.Banner {
    height: 300px;
}

.Banner img.pricingBanner {
    height: 300px;
}

.pricingBanner {
    width: 100%;
    overflow: hidden;
}

.pricingBanner img {
    height: 300px;
}

.pricingHeading {
    font-size: 30px;
    font-weight: 600;
    color: #353b48;
}

.pricingHeadingThird {
    font-size: 21px;
    font-weight: 600;
    color: #353b48;
}

.ServicList {
    padding-left: 20px;
}

.ServicList li {
    padding-left: 20px;
    font-size: 18px;
}

.mainList {
    list-style: none;
    font-size: 23px;
    padding-left: 0px;
}

.bannerText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.bannerHeading {
    color: #ffffff;
    font-weight: 700;
}

.howItWorkHeading {
    font-size: 30px;
    font-weight: 800;
}

#searchHeading {
    margin-top: 27%;
    margin-left: 31%;
}

#searchHeadingSecond {
    margin-left: 31%;
}

.chooseBanner {
    margin-left: 21%;
}

#chooseHeading {
    margin-top: 20%;
}

#connectHeading {
    margin-top: 16%;
    margin-left: 29%;
}

#connectHeadingSecond {
    margin-left: 29%;
}

.payBanner {
    margin-left: 21%;
}

#payHeading {
    margin-top: 21%;
}

.lineSpace {
    margin-top: -14px;
}

.priceMainHeading {
    color: #353b48;
    font-size: 40px;
    font-weight: 600;
}

.dot {
    height: 11px;
    width: 11px;
    background-color: #2b5ea6;
    border-radius: 50%;
    display: inline-block;
}

.dots {
    height: 5px;
    width: 5px;
    background-color: #bbb;
    border-radius: 50%;
    display: grid;
    margin: 26px;
}

.firestDots, .firestDots2, .firestDots3, .firestDots4 {
    height: 60px;
    width: 60px;
    background-color: #2b5ea6;
    border-radius: 50%;
    display: grid;
    margin-top: 100px;
    padding: 11px;
    color: #fff;
    font-size: 28px;
    font-weight: 800;
}

.firestDots2, .firestDots3, .firestDots4 {
    position: absolute;
}

.chooseBannerPosition {
    margin-top: -33;
}

.dotSetting {
    margin-bottom: -57px;
    margin-top: 26px;
}

#viewAllButton {
    background-color: #2b5ea6;
    color: #fff;
    padding: 9px 25px;
}

.cardLink {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    /* background-clip: border-box; */
    border: 1px solid #c1c5c7;
    border-radius: 4px;
    height: 40px;
    padding: 7px;
    margin-bottom: 10px;
    color: #2b5ea6;
    font-size: 1.1rem;
    word-break: break-all;
    white-space: normal;
    overflow: hidden;
}

.estimate {
    border: 1px solid gainsboro;
    text-align: center;
    border-radius: 12px;
    padding: 20px;
    float: left;
}

.hourStyle {
    padding-left: 0px;
    padding-right: 0px;
    background-color: #2b5ea6;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    color: #fff;
}

.helpLine {
    font-size: 20px;
    font-weight: 600;
    padding-left: 27px;
    padding-top: 11px;
    padding-bottom: 11px;
    color: #fff;
}

.helpNunber {
    padding-left: 27px;
}

.helpNunber svg {
    margin-right: 10px;
}

.wrapper {
    width: 600px;
    margin: 0 auto;
}

.accordion-wrapper {
    &+* {
        margin-top: 0.5em;
    }
}

.accordion-item {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
}

.accordion-item.collapsed {
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
    font-weight: 600;
    cursor: pointer;
    color: #666;
    padding: 0.5em 1.5em;
    border: solid 1px #ccc;
    /* border-radius: 1.5em; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    border-radius: 5px;
}

.accordion-title:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid currentColor;
}

.accordion-title:hover, .accordion-title.open {
    color: black;
}

&.open {
    &::after {
        content: "";
        border-top: 0;
        border-bottom: 5px solid;
    }
}

.accordion-content {
    padding: 1em 1.5em;
}

.orders {
    border-radius: 20px;
    background-color: transparent;
    color: black;
    border-color: black;
    width: 100px;
}

.userNotifications {
    font-size: 38px !important;
    color: #ff9a00f5 !important;
}

.firstSection {
    margin-top: 10%;
    margin-bottom: 10%;
}

.css-yk16xz-control, .css-yk16xz-control {
    border: none !important;
}

.css-1wa3eu0-placeholder {
    color: #ccc !important;
    font-size: 19px;
    font-weight: 300;
}

.Homepage {
    overflow-x: hidden;
}

.homepageHeadingFirst {
    font-weight: 600;
    font-family: initial;
    font-size: 45px;
}

.BeforeLogin {
    position: relative;
    top: 25%;
    left: 18%;
}

.all-Orders {
    overflow: hidden;
}

.eyesclick {
    position: absolute;
    right: 10px;
    top: 50%;
    display: block;
    width: 14px;
    transform: translateY(-50%);
    z-index: 1;
    opacity: 0;
    cursor: pointer;
}

.eyes {
    position: absolute;
    right: 7px;
    top: 44%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    text-align: center;
}

.eyes::after {
    content: '\f06e';
    font-family: FontAwesome;
    color: #666;
}

.eyesclick:checked~.eyes::after {
    content: "\f070";
    font-family: FontAwesome;
    color: #333;
}

.litecolor {
    color: #666;
}

.totalOrders {
    background-color: #fff;
    height: 46px;
    padding-top: 11px;
    border-bottom: 3px solid;
}

#cancelPost {
    border-radius: 20px;
    background-color: #2b5ea6;
    color: #FFF;
    border-color: #2b5ea6;
    width: 205px;
}

.headerLine {
    margin-top: 15px;
    margin-bottom: 0px;
    border: 1px solid;
}

.header {
    background: rgb(43, 94, 166) !important;
}

.table.text-center th a {
    position: relative;
    display: block;
    padding: .5rem 1rem;
}

.table.text-center th a::before {
    content: "View Detail";
    position: absolute;
    left: 50%;
    top: -2px;
    font-size: 11px;
    font-weight: 600;
    padding: 3px 5px;
    border-radius: 5px;
    transform: scale(0) translate(-50%, -50%);
    transition: 0.5s;
    z-index: 99;
    color: #000;
}

.MuiButton-label {
    font-size: 20px;
}

.needhlp {
    background-color: green;
    border: 1px solid #666;
    border-radius: 100%;
    width: 35px !important;
    height: 35px;
    display: inline-block;
    margin-top: -5px;
    text-align: center;
    line-height: 35px;
    color: #fff;
    margin-left: 5px;
}

.table.text-center th a:hover::before {
    transform: scale(1) translate(-50%, -50%);
}

.imagessliderprofessional .slick-slide img {
    margin: 0 auto;
}

.header p {
    color: #fff !important;
}

.blogImage {
    width: 100%;
}

.blogContent {
    border: 1px solid #cbcfd4;
    padding: 20px;
    margin: 20px;
}

.blogWriter {
    color: #bd3d3d;
}

.blogContent .blogText, p {
    color: #535658;
}

a:hover {
    text-decoration: none;
}

.breadcrumb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0.75rem 0rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #ffffff;
}

.breadcrumbColor {
    color: #bd3d3d;
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    color: #bd3d3d;
    content: ">";
}

.author-name {
    margin-left: 30px;
}

.about-author {
    display: block;
    font-size: 16px;
    margin-top: 8px;
}

.rating i {
    color: #dedfe0;
}

.professionalImage {
    position: relative;
    margin-top: 5px;
    display: inline-flex;
}

.professionalImage>img {
    border-radius: 100%;
    width: 110px;
    height: 110px;
    object-fit: cover;
}

.professionalReview {
    display: block;
    color: #6a6d8f;
    font-size: 13px;
    margin-top: 5px;
}

iframe {
    width: 100%;
}

iframe.documentshowtag img {
    width: 100% !important;
}

.professionalName {
    font-size: large;
    margin-left: 30px;
    font-weight: 600;
    width: calc(100% - 110px);
}

.professionalName p:last-child {
    font-weight: 400;
}

.professionalName p {
    margin-bottom: 0.5rem;
}

.professionalEstimate {
    text-align: center;
    border-radius: 12px;
    padding: 10px 20px 20px;
    float: left;
}

.user-panel .info p {
    margin: 0;
    color: #c2c7d0;
}

.paddCss p span {
    margin-right: 7px;
}

.notif.MuiSvgIcon-root {
    color: #e8b702;
    font-size: 45px;
    margin-right: 20px;
}

.user-panel .info p span {
    width: 8px;
    height: 8px;
    background-color: #00bf00;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 2px;
}

.reviewAlignment {
    margin-left: 7px;
    margin-bottom: 10px;
}

#header-menu li {
    position: relative;
}

#header-menu li .costmre {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: block;
    min-width: 150px;
    position: absolute;
    right: 0;
    top: 100%;
    overflow: hidden;
    display: none;
    z-index: 9999;
}

#header-menu li:hover .costmre {
    display: block;
}

#header-menu li .costmre ul {
    padding: 0;
}

#header-menu li .costmre ul li {
    text-decoration: none;
    list-style: none;
    padding: 0;
}

#header-menu li .costmre ul li:first-child {
    border-bottom: 1px solid #ccc;
}

#header-menu li .costmre ul li a {
    font-size: 16px;
    color: #666;
    padding: 8px 15px;
    transition: 0.5s;
    display: block;
}

#header-menu li .costmre ul li:hover a {
    background-color: rgb(43, 94, 166);
    color: #fff;
}

input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

.review {
    font-size: 13px !important;
    width: 100% !important;
    float: left;
}

.review p, .reviewer-name p {
    margin: 0 !important;
}

.professionalSearch {
    overflow: hidden;
    margin-top: -16px;
}

.filterBox {
    border-right: 1px solid #8c8585;
}

.filterByBox {
    padding-top: 25px;
    padding-left: 25px;
    border-bottom: 1px solid #8c8585;
    padding-bottom: 20px;
}

.filterByBox h5 {
    font-weight: 700;
}

.availability {
    border-radius: 20px;
    background-color: #2b5ea6;
    color: #FFF;
    border-color: #2b5ea6;
    width: 205px;
}

.providerBox {
    padding: 100px;
    margin-top: -90px;
}

.professionals {
    text-align: center !important;
    display: block;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 10px;
}

.headerBorder {
    border: 1px solid #8c8585;
}

.professionalLink {
    color: #353b48;
}

.viewMore {
    cursor: pointer;
    color: #0056b3 !important;
}

.costumetsf {
    position: relative;
}

.paddCss label {
    font-size: 16px;
}

.modal-backdrop {
    z-index: 999999;
}

.ave .undefined span {
    color: #ffd700 !important;
}

.ave, .nonerat {
    float: left !important;
}

.nonerat .undefined span {
    color: gray !important;
}

.modal {
    z-index: 9999999;
}

.rightbanner {
    float: left;
}

.leftbanner {
    float: right;
}

.reviewso {
    white-space: nowrap;
    max-width: 10ch;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-flex;
}

.successfulbookrv {
    text-align: justify;
    display: block;
    font-size: 18px;
    color: #666;
    margin-bottom: 40px;
    font-weight: 400;
}

.revwbox a {
    border: none;
    background-color: transparent;
    color: rgb(43, 94, 166) !important;
    font-size: 11px;
    cursor: pointer;
}

.questionpopup {
    width: 100%;
    max-width: 800px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    z-index: 99;
    text-align: center;
}

.makeStyles-profile-3 {
    margin-right: -5px !important;
}

#guestloginselect, #guestlogin {
    display: none;
}

#questions, #time_on, #notpad, #successtab, #similer {
    display: none;
}

.notepad h2 {
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    margin: 15px 0;
}

.notepad textarea {
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #6666668c;
}

.notepad .header2 {
    border: 1px solid #6666668c;
    padding: 20px;
    border-radius: 5px;
    position: relative;
}

.notepad .header2 i {
    font-size: 50px;
    color: #ccc;
}

.notepad .header2 input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    right: 0;
    bottom: 0;
}

.chose {
    padding: 4px 30px;
    border-radius: 9999px;
    text-align: center;
    background: #2b5ea6;
    color: #fff;
    border: none;
    margin-top: 10px;
}

.notepad .header2 span {
    text-align: center;
    display: block;
    margin-top: 6px;
    position: relative;
}

.notepad .header2 span::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 47%;
    height: 1px;
    background: #6666668c;
    z-index: -1;
}

.select-action {
    padding: 0 2rem;
}

.datepopup, .notepad {
    padding: 25px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.19);
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 19;
    width: 100%;
    max-width: 700px;
    text-align: center;
}

.logoutbtn {
    background-color: transparent;
    border: none;
    color: blue;
    margin-top: 10px;
    padding: 6px 30px;
}

.upload-button {
    margin: 0 !important;
}

.booktime {
    float: left;
    width: 100%;
}

.dropdown2 {
    position: absolute;
    background: #fff;
    padding: 10px 0;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    right: 0;
    top: 100%;
    min-width: 300px;
    width: 100%;
    z-index: 9999;
}

.dropdown2 h2 {
    padding: 0;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    padding: 0 20px 10px;
    margin: 0;
}

.dropdone .dropdown2 {
    display: none;
}

.dropdone:hover .dropdown2 {
    display: block;
}

.highlight {
    background-color: #ffeaea;
}

.dropdown2 ul {
    padding: 0;
    max-height: 400px;
    overflow-y: scroll;
}

.dropdown2 ul li {
    list-style: none;
    border-top: 1px solid #eee;
    padding: 10px 20px;
    float: left;
    width: 100%;
}

.dropdown2 ul li span {
    float: left;
    line-height: normal;
    font-size: 14px !important;
    color: #333 !important;
}

.dropdown2 ul li span:first-child {
    width: 40px;
}

.costmr {
    margin-top: 2px !important;
    margin-right: 17% !important;
}

.notif.MuiSvgIcon-root {
    color: #ffc107;
}

.badge-warning {
    border: 1px solid #fff;
}

.dropdown2 ul li span:last-child {
    width: calc(100% - 40px);
    padding-left: 10px;
}

.dropdown2 ul li span:last-child button {
    background: transparent;
    border: none;
    padding: 0;
    color: #2b5ea6;
}

.dropdown2.costumernot ul li span:last-child {
    padding-bottom: 10px;
    font-size: 14px;
}

.booktime p {
    margin: 5px 0 !important;
    display: block;
    float: left;
    width: 100%;
}

.datepopup .makeStyles-container-1 {
    display: block;
    text-align: center;
}

.booktime p span:nth-child(1) {
    text-align: center;
    color: red;
}

.booktime p span {
    width: 33.33%;
    display: block;
    float: left;
    text-align: left;
}

.datepopup .makeStyles-textField-2 {
    width: 133px !important;
}

.datepopup>button, .notepad>button, .questionpopup>button {
    position: absolute;
    right: 0;
    top: -2px;
    border: none;
    background-color: transparent;
}

.datepopup>button svg, .notepad>button svg, .questionpopup>button svg {
    font-size: 16px;
}

.notepad {
    z-index: 999;
}

.successtab {
    z-index: 9999;
}

.datepopup h2 {
    font-size: 20px;
    font-weight: 600;
    padding: 0 0;
}

.booktime {
    border-bottom: 1px solid #eee;
    text-align: center;
    padding: 8px;
    margin-bottom: 25px;
}

.notepad .header2 span::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 47%;
    height: 1px;
    background: #6666668c;
    z-index: -1;
}

.status {
    font-size: 28px;
    color: #333;
}

.liveopbtn {
    padding: 0 !important;
}

.textcenter {
    text-align: center;
}

.notepad .header2 p {
    color: #666;
    margin-top: 15px;
    font-weight: 500;
    margin-bottom: 0;
}

.shadow::before {
    content: '';
    background: rgba(0, 0, 0, 0.39);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
}

.questionpopup p {
    font-size: 17px;
    color: #000;
    font-weight: 700;
}

.questionpopup form {
    float: left;
}

.questionpopup .option {
    width: 50% !important;
    float: left;
    padding: 10px 0 0;
}

.questionpopup .option input {
    width: 20px !important;
    float: left;
    margin-top: 4px;
}

.questionpopup .option label {
    width: calc(100% - 28px);
    float: left;
}

.questionpopup form {
    float: left;
    text-align: left;
}

.questionpopup .option:nth-child(2n+2) {
    text-align: left;
}

.questionpopup .option label {
    margin-left: 8px;
    font-size: 16px;
}

.questionpopup .slide-items .item:hover {
    box-shadow: none;
}

.questionpopup .slick-prev, .questionpopup .slick-next {
    /* top: 80% !important; */
    /* width: 130px !important; */
    /* height: 32px !important; */
    opacity: 0;
    /* z-index: 999; */
}

.questionadmin p {
    font-size: 18px;
    font-weight: 500;
}

.questionadmin h2 {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 0;
}

.questionadmin textarea {
    width: 100%;
    border: 2px solid #ccc;
    border-radius: 4px;
    padding: 20px;
}

.questionadmin label {
    margin-left: 7px;
}

.upsbtnide {
    z-index: 99999;
    position: relative;
}

/* .questionpopup .slick-prev {
  left: calc(50% - 135px) !important;
} */

.questionpopup .slick-next {
    right: calc(50% - 135px) !important;
}

.buttonn {
    /* float: left; */
    width: 130px;
    padding: 4px 30px;
    border-radius: 9999px;
    border: 2px solid #333;
    color: #333;
    background: #fff;
    margin-top: 30px;
    transition: 0.5s;
}

.buttons {
    max-width: 270px;
    margin: 0 auto;
    clear: both;
}

.next {
    margin-left: 10px;
    background: #2b5ea6;
    color: #fff;
    border-color: #2b5ea6;
}

.buttonn:hover {
    background: #2b5ea6;
    color: #fff;
    border-color: #2b5ea6;
}

.next:hover {
    border-color: #333;
    color: #333;
    background: #fff;
}

/* card css */

.zoomprofessional ul {
    padding: 0;
    margin-top: 0px;
}

.zoomprofessional ul li {
    width: 100px;
    display: inline-block;
    height: 75px;
    margin-right: 15px;
    position: relative;
    margin-bottom: 15px;
}

.zoomprofessional ul li button {
    background: transparent;
    border: none;
    position: absolute;
    right: -8px;
    top: -8px;
}

.zoomprofessional ul li button svg {
    fill: #000;
    width: 20px;
    height: 20px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    padding: 2px;
    border-radius: 50%;
}

.zoomprofessional .image_Preview {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
}

.loginfooter button {
    border: none;
    color: #fff !important;
    text-align: left !important;
    padding: 0;
}

.questionpopup .option:nth-child(2n+2) {
    text-align: left;
}

.setwel {
    position: absolute;
    left: 49%;
    top: 45%;
    transform: translate(-50%, -50%);
    font-size: 42px;
    font-weight: 700 !important;
}

.ftr, .list-unstyled li a {
    font-size: 1rem;
}

#messagepassford {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    z-index: 999;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    padding: 10px;
    border-radius: 5px;
    box-sizing: border-box;
}

#messagepassford h3 {
    font-size: 13px;
    margin-bottom: 5px;
    color: gray;
}

#messagepassford p {
    font-size: 12px;
    margin-bottom: 5px;
    color: gray;
}

.AdminContainer {
    position: relative;
}

.questionpopup form {
    float: left;
    text-align: left;
}

.container2 {
    width: 100%;
    overflow: hidden;
    padding: 0 20px;
}

.savebutton button {
    position: relative;
}

.savebutton input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
}

.cstm tr {
    /* background: rgb(234, 67, 55) none repeat scroll 0% 0%; */
}

.AdminLiveOperation, .AdminContainer {
    padding-right: 0.1%;
}

.mange-service-heading {
    width: 100%;
    margin-left: 0px;
}

.headi {
    font-size: 23px;
    font-weight: 700;
    color: #333;
    margin: 0;
    padding: 10px;
    margin-top: -45px;
}

.cstm tr th {
    /* color: #fff; */
    font-weight: 500;
    font-size: 17px;
    padding: 0;
    display: block;
}

.record {
    padding: 1px 15px;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.datesearch {
    width: 100%;
    display: inline-block;
    margin-bottom: 10px;
}

.datesearch input {
    font-size: 15px;
}

.documetimg {
    width: 100px;
    height: 100px;
}

.forgetdesig {
    width: 100%;
}

.forgetdesig h2 {
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    padding: 0;
    margin-top: 8px;
}

.forgetdesig input::placeholder {
    font-size: 16px;
}

.rating .undefined span {
    /* color: #ccc !important; */
}

.datesearch input {
    min-width: 300px;
}

.datesearch.poi input {
    min-width: 130px;
}

.h2set {
    padding: 0;
    margin: 0;
    font-size: 22px;
    font-weight: 600;
    margin-top: -27px;
}

.forgetdesig h1 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 600;
}

.forgetdesig a {
    margin-top: 20px;
    display: block;
}

.fileset {
    position: absolute;
    right: 15px;
    top: 0;
    width: 100%;
    z-index: 99;
    max-width: 130px;
    opacity: 0;
    cursor: pointer;
}

.contentBorder.admner table tr {
    display: block;
    width: 100%;
    float: left;
    padding: 0 !important;
    border-bottom: 1px solid #ccc;
}

.contentBorder.admner table tr:last-child {
    border: none;
}

.contentBorder.admner table {
    margin-bottom: 0;
}

.contentBorder.admner table td {
    border: none;
    padding: 15px;
}

.contentBorder.admner table td:first-child {
    float: left;
}

.contentBorder.admner table td:last-child {
    float: right;
}

.design button {
    padding: 0 !important;
    background-color: transparent !important;
    color: #212529 !important;
    border: none !important;
    width: 100% !important;
}

.design.buttonn:hover button {
    color: #fff !important;
}

.datesearch input {
    width: 120px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, .125);
    height: 34px;
}

.datesearch input::placeholder {
    color: #333;
    font-size: 14px;
    font-weight: 600;
}

.datesearch span {
    margin: 0 20px;
}

.datesearch button {
    margin-left: 10px !important;
    height: 33px !important;
    line-height: 19px !important;
}

.record span:first-child {
    float: left;
}

.record span:last-child {
    float: right;
}

.cstm tr th button {
    border: none;
    background: transparent;
    padding: 15px;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.cstm tr:last-child th button {
    border: none;
}

.makeStyles-textField-32 {
    width: 131px !important;
}


.intro h1, .intro h2, .intro h3 {
    font-size: 18px;
    margin: 0;
    font-weight: 600;
}

.setdrop {
    top: 22px !important;
}

.introall h1, .introall h2, .introall h3 {
    font-size: 18px;
    margin: 0 0 10px 0;
    font-weight: 600;
}

.MuiButton-label a {
    color: #000;
}

.makeStyles-tooltip-4 {
    top: 16px !important;
    left: -19px !important;
}

#point {
    display: none;
}

.loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #3498db;
    width: 200px;
    height: 200px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
    position: absolute;
    left: 46%;
    top: 40%;
}

/* Safari */

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.drop-message {
    position: relative;
    overflow: hidden;
}

.drop-message #profile_pic_upload {
    top: 16px !important;
    cursor: pointer;
}

.lod {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9;
    padding: 23px;
    border-radius: 8px;
    display: none;
}

.firstSection .css-1g6gooi input {
    padding-left: 0 !important;
}

#time_on .jss1 {
    display: block;
    text-align: center;
}

.design .jss32, .datepopup .jss2 {
    width: 125px;
}